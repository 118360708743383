import axios from "axios";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AuthModal from "./AuthModal";
import SocialShare from "./SocialShare";
import useIntersectionObserver from "./useHook";

const VideoElement = forwardRef(
  (
    {
      product,
      setProduct,
      index,
      video,
      muted,
      setMuted,
      inView,
      isPlaying,
      setIsPlaying,
    },
    ref
  ) => {
    const isMobile =
      /Mobile|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    const videoRef = useRef(null);
    const [shareModal, setShareModal] = useState(false);
    const [shareLoading, setShareLoading] = useState(false);
    const [likeLoading, setLikeLoading] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(false);

    const handleTogglePlay = () => {
      const video = videoRef.current;

      if (video) {
        if (isPlaying) {
          video.pause();
        } else {
          video.play();
        }

        setIsPlaying(!isPlaying);
      }
    };

    const handleShare = async () => {
      setShareLoading(true);
      await axios
        .get(
          `${process.env.REACT_APP_BASEURL}user/shareVideo/share?productId=${product._id}&videoId=${video._id}`
        )
        .then((res) => {
          setProduct({
            ...product,
            videos: product.videos.map((item) =>
              item._id !== video._id
                ? item
                : { ...item, shares: item.shares + 1 }
            ),
          });
          setShareLoading(false);
        })
        .catch((error) => {
          setShareLoading(false);
          toast.error(error.response.data.message);
        });
    };
    const handleLike = async (videoId) => {
      setLikeLoading(true);
      await axios
        .get(
          `${process.env.REACT_APP_BASEURL}user/likeVideo/toogleLike?productId=${product._id}&videoId=${videoId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setProduct({
            ...product,
            videos: product.videos.map((item) =>
              item._id !== videoId
                ? item
                : item.isLiked
                ? { ...item, isLiked: false, likes: item.likes - 1 }
                : { ...item, isLiked: true, likes: item.likes + 1 }
            ),
          });
          setLikeLoading(false);
        })
        .catch((error) => {
          setLikeLoading(false);
          toast.error(error.response.data.message);
        });
    };
    const handleDownload = async (videoId, videoUrl) => {
      setDownloadLoading(true);
      await axios
        .get(
          `${process.env.REACT_APP_BASEURL}user/downloadVideo/download?productId=${product._id}&videoId=${videoId}`
        )
        .then(function () {
          setDownloadLoading(false);
          const url = videoUrl;
          const a = document.createElement("a");
          a.href = url;
          a.download = "video.mp4";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          setProduct({
            ...product,
            videos: product.videos.map((item) =>
              item._id !== videoId
                ? item
                : { ...item, downloads: item.downloads + 1 }
            ),
          });
        })

        .catch(function (error) {
          setDownloadLoading(false);
          console.error("API call error:", error);
        });
    };
    const componentRef = useRef(null);
    const componentRefWithIntersection = (element) => {
      componentRef.current = element;
      setNodeRef(element);
    };
    const [setNodeRef, entry] = useIntersectionObserver({
      threshold: 0.5,
    });

    const shareData = {
      title: "BioSOUTH",
      text: `Check out this awesome video! ${video.title}`,
      url: window.location.href,
    };

    const handleShareClick = () => {
      if (navigator.share) {
        navigator
          .share(shareData)
          .then(() => {
            handleShare();
            console.log("Shared successfully");
          })
          .catch((error) => {
            console.error("Error sharing:", error);
          });
      }
    };

    useEffect(() => {
      const video = videoRef.current;

      if (video && isPlaying && inView) {
        // Check if the video is already loaded
        if (video.readyState >= 2) {
          video.play();
        } else {
          video.load(); // Load the video if not already loaded
          video.play();
        }
      }
    }, [isPlaying, videoRef, inView]);

    useEffect(() => {
      const video = videoRef.current;

      if (video) {
        if (entry.isIntersecting) {
          // Check if the video is already loaded
          if (video.readyState < 2) {
            video.load();
          }
          video.play();
        } else {
          video.pause();
        }
      }
    }, [entry, videoRef, entry.isIntersecting]);

    return (
      <div ref={componentRefWithIntersection} key={index}>
        {shareModal && (
          <SocialShare
            setShareModal={setShareModal}
            handleShare={handleShare}
            shareLoading={shareLoading}
          />
        )}
        {showAuthModal && <AuthModal setShowAuthModal={setShowAuthModal} />}
        <div id={video._id}></div>
        <section
          className="tik_tok_vedio"
          style={{ height: window.innerHeight - 47 }}
          key={index}
        >
          <div className="about-video-wrapper2">
            <video
              id="videoPlayer"
              ref={videoRef}
              controls={false}
              loop
              muted={muted}
              webkit-playsinline
              playsInline
              // style={{ height:"90vh",  }}
            >
              <source
                src={process.env.REACT_APP_BUCKET_BASE_URL + video.video}
                type="video/mp4"
              />{" "}
            </video>
            <div className="container">
              <div className="social_icon_btn">
                <ul className="bg-social">
                  <div>
                    <li className="mb-3">
                      <Link to={`/product?id=${product._id}`}>
                        <p>
                          <img
                            src="assets/images/cross.svg"
                            alt="cros"
                            class="img-fluid"
                          />
                        </p>
                      </Link>
                    </li>
                    <li className="mb-3" onClick={handleTogglePlay}>
                      <p>
                        <img
                          src={
                            isPlaying
                              ? "assets/images/pause.svg"
                              : "assets/images/play.svg"
                          }
                          alt="cros"
                          class="img-fluid"
                          width={isPlaying ? 35 : ""}
                        />
                      </p>
                    </li>
                    <li>
                      <p
                        onClick={() => {
                          if (isMobile) {
                            handleShareClick();
                          } else {
                            setShareModal(true);
                          }
                        }}
                      >
                        <img
                          src="assets/images/share.svg"
                          alt="share"
                          className="img-fluid"
                        />
                      </p>
                      <span className=" text-white text-center">
                        {video.shares}
                      </span>
                    </li>
                    <li>
                      <p
                        onClick={() => {
                          if (localStorage.getItem("token")) {
                            if (!likeLoading) handleLike(video._id);
                          } else {
                            setShowAuthModal(true);
                          }
                        }}
                      >
                        <img
                          src={
                            video.isLiked
                              ? "assets/images/liked.svg"
                              : "assets/images/like.svg"
                          }
                          alt="heart"
                          className="img-fluid"
                        />
                      </p>
                      <span className=" text-white text-center">
                        {video.likes}
                      </span>
                    </li>
                    {/* <li>
            <p>
              <img
                src="assets/images/comment.svg"
                alt="comment"
                className="img-fluid"
              />
            </p>
            <span className=" text-white text-center">6.7k</span>
          </li> */}
                    <li>
                      <p
                        onClick={() => {
                          if (!downloadLoading)
                            handleDownload(
                              video._id,
                              process.env.REACT_APP_BUCKET_BASE_URL +
                                video.video
                            );
                        }}
                      >
                        <img
                          src="assets/images/download.svg"
                          alt="download"
                          className="img-fluid"
                        />
                      </p>
                      <span className=" text-white text-center">
                        {video.downloads}
                      </span>
                    </li>
                    {product && product.videos.length > 0 && (
                      <li
                        className="mb-3 d-flex justify-content-center align-items-center"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "100%",
                        }}
                        onClick={() => {
                          setMuted(!muted);
                        }}
                      >
                        {muted ? (
                          <i
                            class="text-white fa fa-2x fa-volume-off"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            class="text-white fa fa-2x fa-volume-up"
                            aria-hidden="true"
                          ></i>
                        )}
                      </li>
                    )}
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
);

export default VideoElement;
