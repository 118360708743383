import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import AffiliateDropDown from "../../common/AffiliateDropDown";
import Appbar from "../../common/Appbar";

const ProductDetail = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get("id");
  const [product, setProduct] = useState();
  const [affiliateProduct, setAffiliateProduct] = useState();
  const [biodiversity, setBiodiversity] = useState();
  const [decarbonisations, setDecarbonisations] = useState();
  const [loading, setLoading] = useState(true);
  const [showAffiliate, setShowAffiliate] = useState(false);
  const popupRef = useRef();

  const isLoggedIn = localStorage.getItem("token");
  let user;
  if (isLoggedIn) {
    user = JSON.parse(localStorage.getItem("user"));
  }

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_BASEURL}${
            user?.role === "admin"
              ? `admin/product/view/${productId}`
              : `user/product/${
                  isLoggedIn ? "viewAuthenticated" : "view"
                }/${productId}`
          }`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setProduct(res.data.data);
          setAffiliateProduct(res.data.data.affiliateProducts);
          setBiodiversity(res.data.data.biodiversities);
          setDecarbonisations(res.data.data.decarbonisations);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setLoading(false);
        });
    };
    fetchData();
  }, []);

  return (
    <div>
      <Appbar />
      {loading ? (
        <ThreeDots
          height="40"
          width="80"
          radius="9"
          color="#3D83F5"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            display: "flex",
            justifyContent: "center",
            marginTop: "150px",
          }}
          wrapperClassName="spinner"
          visible={true}
        />
      ) : (
        product && (
          <>
            <div className="shop_modal">
              <div className="shop_header">
                <div className="container ">
                  <div className="head_wrap d-flex justify-content-between align-items-center">
                    <h1 className="f-20 mb-0" style={{ fontWeight: "700" }}>
                      {product.name}
                    </h1>
                    <div className="shop_btn">
                      {affiliateProduct &&
                        affiliateProduct.length > 0 &&
                        !showAffiliate && (
                          <button
                            className="themeBtn-blue"
                            onClick={() => {
                              setShowAffiliate(true);
                            }}
                          >
                            Shop
                          </button>
                        )}
                      {showAffiliate && (
                        <button
                          className="cross_btn"
                          onClick={() => {
                            setShowAffiliate(false);
                          }}
                        >
                          <img
                            src="assets/images/cros.png"
                            alt="cros"
                            className="img-fluid"
                          />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {showAffiliate && (
                <div className="shop_body" id="shop_body">
                  <div
                    className="container "
                    ref={popupRef}
                    style={{
                      maxHeight: "50vh",
                      overflow: "auto",
                      padding: "10px",
                    }}
                  >
                    {affiliateProduct &&
                      affiliateProduct.length > 0 &&
                      affiliateProduct.map((item, index) => (
                        <AffiliateDropDown
                          key={index}
                          item={item}
                          setShowAffiliate={setShowAffiliate}
                          popupRef={popupRef}
                        />
                      ))}
                  </div>
                </div>
              )}
            </div>
            <section className="banner-wrap">
              <div className="container ">
                <div className="row align-items-start">
                  <div className="col-md-6">
                    <h1
                      className="text-center mx-3 pb-5 "
                      style={{ fontWeight: "800", fontSize: "40px" }}
                    >
                      {product.description}
                    </h1>{" "}
                  </div>
                  <div className="col-md-6 text-center">
                    <div className="banner-wrap-img">
                      {" "}
                      <img
                        src={
                          process.env.REACT_APP_BUCKET_BASE_URL + product?.image
                        }
                        alt="light-botl"
                        className="img-fluid"
                      />{" "}
                    </div>
                  </div>
                </div>
              </div>
            </section>{" "}
            {product.videos.length > 0 && (
              <>
                <section className="vedio_main">
                  <div className="container">
                    <div className="vedio_main_inner">
                      <div className="row">
                        {product.videos.map((video, index) => (
                          <div className="col-md-4 gap-2">
                            <div className="row">
                              <div
                                className={`col-4 d-flex flex-column align-items-center pb-0 ${
                                  productId == "650ae4d316b3888e7e410f6f" &&
                                  index == 3
                                    ? "pt-3"
                                    : "pt-1"
                                } `}
                              >
                                <div className="production_section  ">
                                  <p
                                    style={{
                                      paddingLeft: "20px",
                                      paddingTop: "20px",
                                    }}
                                  >
                                    {video.productionDate.split("-")[0]}
                                  </p>
                                  <p
                                    style={{
                                      paddingLeft: "20px",
                                    }}
                                  >
                                    {video.productionDate.split("-")[1]}
                                  </p>
                                  <h6>{video.location}</h6>
                                </div>
                              </div>
                              <div
                                className={`${
                                  index > 0 && index < product.videos.length - 1
                                    ? "triangle-up"
                                    : "vedio_wrap"
                                } col-8 ${
                                  product.videos.length - 1 === index
                                    ? " before_none"
                                    : ""
                                }`}
                              >
                                <div
                                  className={`${
                                    product.videos.length - 1 === index
                                      ? " before_none"
                                      : ""
                                  }`}
                                >
                                  <Link
                                    to={`/video?id=${product._id}&videoId=${video._id}`}
                                  >
                                    <div className="about-video-wrapper">
                                      {video?.thumbnail ? (
                                        <img
                                          id="videoPlayer"
                                          alt="thumbnail"
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            objectFit: "cover",
                                          }}
                                          className="object-fit-cover"
                                          src={
                                            process.env
                                              .REACT_APP_BUCKET_BASE_URL +
                                            video.thumbnail
                                          }
                                        />
                                      ) : (
                                        <video
                                          id="videoPlayer"
                                          preload="metadata"
                                          muted={true}
                                          controls={false}
                                          style={{
                                            objectFit: "cover",
                                            width: "100%",
                                            height: "100%",
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                          }}
                                          src={
                                            process.env
                                              .REACT_APP_BUCKET_BASE_URL +
                                            video.video +
                                            "#t=0.001"
                                          }
                                        ></video>
                                      )}

                                      <div className="vedio_footer d-flex align-items-center  justify-content-between">
                                        <h1
                                          className={`f-16 alignment-left`}
                                          style={{ color: "white !important" }}
                                        >
                                          {video.title}
                                        </h1>
                                        <img
                                          src="assets/images/right_circle.png"
                                          alt="right_circle"
                                          className="img-fluid"
                                          width={30}
                                        />{" "}
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </section>
              </>
            )}
            <section className="sharing_wrap">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 mx-auto text-center">
                    <h5
                      className="f-23"
                      style={{
                        color: "black",
                        textAlign: "left",
                        marginLeft: "10px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                        letterSpacing: "-0.333px",
                        marginBottom: "20px",
                      }}
                    >
                      How this product is supporting nature in the south of
                      England:
                    </h5>
                    {biodiversity && biodiversity.length > 0 && (
                      <h5 style={{ marginBottom: "10px" }} className="f-23">
                        <a
                          href="#Biodiversity"
                          data-bs-toggle="modal"
                          data-bs-target="#Biodiversity"
                          className="link_btn"
                        >
                          Improving Biodiversity{" "}
                          <svg
                            width="8"
                            height="14"
                            viewBox="0 0 8 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              id="Vector"
                              d="M0.335877 13.5852C0.111959 13.3613 0 13.0763 0 12.7302C0 12.3842 0.111959 12.0992 0.335877 11.8753L5.09924 7.11189L0.335877 2.34854C0.111959 2.12462 0 1.83963 0 1.49357C0 1.14752 0.111959 0.86253 0.335877 0.638612C0.559796 0.414693 0.844783 0.302734 1.19084 0.302734C1.53689 0.302734 1.82188 0.414693 2.0458 0.638612L7.66412 6.25693C7.78626 6.37907 7.87298 6.51139 7.92427 6.65388C7.97476 6.79637 8 6.94904 8 7.11189C8 7.27474 7.97476 7.42742 7.92427 7.56991C7.87298 7.7124 7.78626 7.84472 7.66412 7.96686L2.0458 13.5852C1.82188 13.8091 1.53689 13.9211 1.19084 13.9211C0.844783 13.9211 0.559796 13.8091 0.335877 13.5852Z"
                              fill="#2350FF"
                            />
                          </svg>
                        </a>
                      </h5>
                    )}
                    {decarbonisations && decarbonisations.length > 0 && (
                      <h5 className="f-23" style={{ marginTop: "18px" }}>
                        <a
                          href="#mpactReduction"
                          data-bs-toggle="modal"
                          data-bs-target="#mpactReduction"
                          className="link_btn"
                        >
                          Decarbonisation{" "}
                          <svg
                            width="8"
                            height="14"
                            viewBox="0 0 8 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              id="Vector"
                              d="M0.335877 13.5852C0.111959 13.3613 0 13.0763 0 12.7302C0 12.3842 0.111959 12.0992 0.335877 11.8753L5.09924 7.11189L0.335877 2.34854C0.111959 2.12462 0 1.83963 0 1.49357C0 1.14752 0.111959 0.86253 0.335877 0.638612C0.559796 0.414693 0.844783 0.302734 1.19084 0.302734C1.53689 0.302734 1.82188 0.414693 2.0458 0.638612L7.66412 6.25693C7.78626 6.37907 7.87298 6.51139 7.92427 6.65388C7.97476 6.79637 8 6.94904 8 7.11189C8 7.27474 7.97476 7.42742 7.92427 7.56991C7.87298 7.7124 7.78626 7.84472 7.66412 7.96686L2.0458 13.5852C1.82188 13.8091 1.53689 13.9211 1.19084 13.9211C0.844783 13.9211 0.559796 13.8091 0.335877 13.5852Z"
                              fill="#2350FF"
                            />
                          </svg>
                        </a>
                      </h5>
                    )}
                  </div>
                </div>
              </div>
            </section>
            <div className="footer_location">
              {" "}
              <img
                src="assets/images/footer.png"
                alt="footer"
                className="img-fluid w-100 d-blcok"
              />{" "}
            </div>
            <section className="footer_top">
              <div className="container">
                <div className="row brand_wrap">
                  <div className="col-md-7 col-2 mx-auto">
                    <div className="brand_logo d-flex justify-content-between">
                      <img src="assets/images/img1.png" alt="img1" />
                      <img src="assets/images/img2.png" alt="img2" />
                      <img src="assets/images/img3.png" alt="img3" />
                      <img src="assets/images/img4.png" alt="img4" />
                      <img src="assets/images/img5.png" alt="img5" />
                      <img src="assets/images/img6.png" alt="img6" />
                      <img src="assets/images/img7.png" alt="img7" />
                    </div>
                  </div>
                  <div className="col-md-7 col-10 mx-auto">
                    <p className="f-20 text-white text-center fw-normal text_detail">
                      BioSouth is a program that is designed by experts to
                      support nature recovery within the Wessex basin.
                      <br />
                      <br />
                      The BioSouth team works with local businesses of all types
                      to help them understand the critical role that they play
                      in supporting our natural environment..
                      <br />
                      <br />
                      We think that a collective approach that is based on
                      science will pay dividends for nature in the long run.
                      <br />
                      <br />
                      This app showcases products that are made by the
                      businesses we are working with. We hope you enjoy finding
                      out a bit about how their products are made.
                    </p>
                  </div>
                </div>
                <div className="logo_brand_Wrap justify-content-center flex-column ">
                  <a href="https://biosouth.co.uk/">
                    <img src="assets/images/Core-Logo.png" alt="Core-Logo" />
                  </a>
                  <p className="f-20 text-white py-3 ">
                    <a href="http://www.biosouth.co.uk/">www.biosouth.co.uk </a>
                  </p>
                </div>
              </div>
            </section>
            <footer className="footer">
              <div className="container">
                <div className="row">
                  <div className="col-md-7 mx-auto">
                    <div className="link_footer">
                      <ul>
                        <li>
                          <span>Contact sales</span> · <span>Careers</span> ·{" "}
                          <span>Media assets Help</span> ·{" "}
                          <span>Contact support</span> ·{" "}
                          <span>Privacy policy</span> · <span>Imprint</span>
                        </li>
                      </ul>
                    </div>
                    <p className="f-20 text-center text-white">
                      {" "}
                      BioSouth, c/o Salisbury Innovation Accelerator, Wilsons
                      Solicitors LLP, Alexandra House, St Johns Street,
                      Salisbury SP1 2SB{" "}
                    </p>
                    <p className="f-20 text-center text-white">
                      <b>
                        © 2023 BioSouth All rights reservedPrivacy & Cookie
                        Policy
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            </footer>
            <div
              className="modal fade modal_costom"
              id="Search_modal"
              tabindex="-1"
              aria-labelledby="Search_modalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <div className="search_Wrap">
                      <div className="form-group">
                        <div className="icon_fild">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                          />{" "}
                          <img
                            src="assets/images/searchBlack.png"
                            className="icon"
                            alt="img8"
                          />{" "}
                        </div>
                      </div>
                      <div className="search_result">
                        <p>Past Searches</p>
                        <ul className="search_list">
                          <li>
                            <img src="assets/images/right_list_arrw.svg" />
                            <span>Coors Light</span>
                          </li>
                          <li>
                            <img src="assets/images/right_list_arrw.svg" />
                            <span>Heinekn</span>
                          </li>
                          <li>
                            <img src="assets/images/right_list_arrw.svg" />
                            <span>Bud Light</span>
                          </li>
                          <li>
                            <img src="assets/images/right_list_arrw.svg" />
                            <span>Guinness</span>
                          </li>
                          <li>
                            <img src="assets/images/right_list_arrw.svg" />
                            <span>Stella Artois</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {biodiversity && biodiversity.length > 0 && (
              <div
                className="modal scroll_modal fade"
                id="Biodiversity"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="BiodiversityLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog  modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                      <div className="modal_inner_wrap">
                        <h1 className="main-title">Biodiversity</h1>
                        {biodiversity &&
                          biodiversity.length > 0 &&
                          biodiversity.map((item, index) => (
                            <div className="list_item" key={index}>
                              <img
                                src={`${
                                  process.env.REACT_APP_BUCKET_BASE_URL +
                                  item.image
                                }`}
                                alt="img1"
                                width="50px"
                                height="50px"
                                style={{
                                  objectFit: "contain",
                                  borderRadius: "100%",
                                }}
                              />
                              <p className="f-20">
                                <b>{item.title}</b> <span>"{item.desc}"</span>
                              </p>
                            </div>
                          ))}
                        <div> </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {decarbonisations && decarbonisations.length > 0 && (
              <div
                className="modal scroll_modal fade"
                id="mpactReduction"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="mpactReductionLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog  modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                      <div className="modal_inner_wrap">
                        <h1 className="main-title">Decarbonisation</h1>
                        {decarbonisations &&
                          decarbonisations.length > 0 &&
                          decarbonisations.map((item, index) => (
                            <div className="list_item" key={index}>
                              <img
                                src={`${
                                  process.env.REACT_APP_BUCKET_BASE_URL +
                                  item.image
                                }`}
                                alt="img1"
                                width="50px"
                                height="50px"
                                style={{
                                  objectFit: "contain",
                                  borderRadius: "100%",
                                }}
                              />
                              <p className="f-20">
                                <b>{item.title}</b> <span>"{item.desc}"</span>
                              </p>
                            </div>
                          ))}
                        <div> </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )
      )}
    </div>
  );
};

export default ProductDetail;
